import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
  createContext
} from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { toastr } from "react-redux-toastr";
import { Prompt } from "react-router-dom";
import moment from "moment";

// material ui
import {
  Button,
  FormLabel,
  IconButton,
  Checkbox,
  FormControlLabel,
  TextField,
  Divider,
  Switch,
  Radio,
  RadioGroup,
  Tooltip,
  FormControl,
  FormHelperText,
  Grid
} from "@material-ui/core";
import {
  InfoSharp,
  CheckBoxOutlineBlank as CheckBoxOutlineBlankIcon,
  CheckBox as CheckBoxIcon,
  Done as DoneIcon,
  OfflineBoltSharp as TargetIcon,
  OpenInBrowser as OpenInBrowserIcon,
  PublishSharp as PublishIcon,
  CloseSharp as CloseIcon,
  AssignmentTurnedIn as AssignmentTurnedInIcon,
  Help as HelpIcon
} from "@material-ui/icons";

// custom hooks
import { useFormTouched } from "../../hooks/useFormTouched";
import { useModal } from "../../hooks/useModal";
import { useFetchPlatforms } from "./logics/fetchPlatforms.logic";
import { useFetchSearchEngines } from "./logics/fetchSearchEngine.logic";
import { useFetchTagTypes } from "./logics/fetchTagTypes.logic";
// import { useHandleFormValueChange } from "./logics/rule-block/handleFormValueChange.logic";

// components
import ContentHeader from "../../components/ContentHeader/ContentHeader";
import ConfirmBox from "../../components/ConfirmBox/ConfirmBox";
import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";
import InfoToolTip from "../../components/InfoToolTip/InfoToolTip";
import AutoCompleteSelect from "../../components/AutoCompleteSelect/AutoCompleteSelect";
// import SimpleModal from "../../components/SimpleModal/SimpleModal";
import TargetRule from "./TargetRule";
import Alert from "../../components/AlertMessage/AlertMessage";

// services
import { Publisher } from "../../services/Publisher";
import { Setting } from "../../services/Settings";
import { ShareRevenue } from "../../services/ShareRevenue";

// reducers
import { getSettingsObject } from "../../reducers/setting/setting.selector";
import {
  getTargetingRules,
  getTargetingRulesError,
  getTargeting,
  getDeletedTargetingRules
} from "../../reducers/target/target.selector";
import {
  updateTargetingRules,
  updateTargetingFormErrors,
  //   updateTargetingForm,
  clearTargetingForm
} from "../../reducers/target/target.action";
import {
  updateDefaultSettings,
  updateSettingsObject
} from "../../reducers/setting/setting.action";

// layouts
// import History from "./layouts/History";
import FormAddtionalHeaders from "./layouts/FormAddtionalHeaders";
// import DuplicateAdvertiserLinksMsg from "./layouts/DuplicateAdvertiserLinksMsg";
import DuplicateLinksMsg from "./layouts/DuplicateLinksMsg";
import SavingMessage from "./layouts/SavingMessage";

// validations
import { textFieldValid, validURL } from "../../helpers/validation";

// helper functions
import {
  formatTargetDataForSave,
  sumOfInputedWeights,
  areTheWeightsValid,
  isThereLimitlessDailyCap,
  getNumberOfActiveRules,
  ROUND_ROBIN_HELP_TEXT,
  WATERFALL_HELP_TEXT,
  CUSTOM_FALLBACK_HELP_TEXT,
  calculateTotalDailyCap,
  updateTargetingRulesBeforeDispatch
} from "./helper/helper-functions";
import { TARGETING_TYPES } from "../../helpers/constant/types";
import { DEFAULT_FETCH_PARAM_SIZE } from "../../helpers/constant/misc";
import { copyToClipboard } from "../../helpers/index";
import { QualityScoreDropDown } from "../../helpers/constant/quality-score";
import { isArray } from "lodash";
import SimpleAccordion from "../../components/Accordion/Accordion";
import { useGetTagStats } from "./logics/getTagStats.logic";
import { DRAFT_INFO_MESSAGE } from "./constants";
import { useFetchLatestHistory } from "./logics/fetchLatestHistory";

export const TagTypeContext = createContext([]);
export const PlatformContext = createContext([]);
export const SearchEngineContext = createContext([]);

export default function Index() {
  const history = useHistory();
  const settingsObject = useSelector(getSettingsObject);
  const [DEFAULT_DOMAIN, setDEFAULT_DOMAIN] = useState(
    () => settingsObject?.default_domain || ""
  );
  const [DEFAULT_FALLBACK, setDEFAULT_FALLBACK] = useState(
    () => settingsObject?.system_fallback || ""
  );

  const SYSTEM = process.env.REACT_APP_SYSTEM;
  const { platforms } = useFetchPlatforms();
  const { searchEngines } = useFetchSearchEngines();
  const { tagTypes } = useFetchTagTypes();

  const [platform, setPlatform] = useState("");
  const [searchEngine, setSearchEngine] = useState("");
  const [tagType, setTagType] = useState("");
  const [qualityScore, setQualityScore] = useState("");
  const [dailyCap, setDailyCap] = useState("");

  const targetingRules = useSelector(getTargetingRules);
  const targetingRulesError = useSelector(getTargetingRulesError);
  const deletedTargetingRules = useSelector(getDeletedTargetingRules);
  const [oldRules, setOldRules] = useState(null);
  const initialTargeting = useSelector(getTargeting);

  const dispatch = useDispatch();
  const stableDispatch = useCallback(dispatch, [dispatch]);
  const [rulesBlockNumber, setRulesBlockNumber] = useState(0);
  const [loading, setLoading] = useState(true);
  const [publisher, setPublisher] = useState(initialTargeting.publisher);
  const [isWeightFieldTouched, setIsWeightFieldTouched] = useState(false);

  const [state, setState] = useState({
    cusDomain: initialTargeting.cusDomain,
    cusFallback: initialTargeting.cusFallback,
    cusOvercapUrl: initialTargeting.cusOvercapUrl,
    useN: initialTargeting.useN,
    useClickId: initialTargeting.useClickId,
    warmupMode: false,
    useSubId: initialTargeting.useSubId,
    domain: initialTargeting.domain,
    subID: initialTargeting.subID,
    clientID: initialTargeting.clientID,
    fallback: initialTargeting.fallback,
    overcapUrl: initialTargeting.overcapUrl,
    targeting: initialTargeting.targeting,
    notes: initialTargeting.notes,
    tagDescription: initialTargeting.tagDescription,
    status: initialTargeting.status,
    updatedAt: initialTargeting.updatedAt,
    publishedAt: initialTargeting.publishedAt,
    passReferrer: false,
    IPListProxy: true,
    IPListTooManyPerIP: true,
    minimumChromeVersion: true,
    minimumWindowsVersion: true,
    shortKeywords: true,
    useLatency: true,
    latency: 1000,
    useHighPayingKeywords: false,
    highPayingKeywords: 10,
    temporarilyDisableFilters: true
  });
  const [activate, setActivate] = useState(initialTargeting.is_active);
  const [publishers, setPublishers] = useState(null);
  const [disabledPublishers, setDisabledPublishers] = useState([]); //publishers whose status is inactive
  const [targetLink, setTargetLink] = useState(initialTargeting.link);
  const [editing, setEditing] = useState(false);
  const [logicError, setLogicError] = useState(false);
  const [sendingFormData, setSendingFormData] = useState(false);
  const [saveDraftStatus, setSaveDraftStatus] = useState(false);

  //checking the url param to see if edit page is to be used
  const { pathname, search } = useLocation();
  const { id } = useParams() || null;
  const searchParams = useMemo(() => new URLSearchParams(search), [search]);
  const duplicateId = searchParams.get("duplicate");
  const [targetingID, setTargetingID] = useState();
  const [showPrompt, setShowPrompt] = useState(true);
  const { publisherChannelStats } = useGetTagStats(id);

  // latest history
  const [historyPage, setHistoryPage] = useState(1);
  const [startFetchHistory, setStartFetchHistory] = useState(false);
  const historyLimit = 5;
  const { latestHistoryData, isLatestHistoryLoading, totalHistoryCount } =
    useFetchLatestHistory({
      id: id,
      page: historyPage,
      limit: historyLimit,
      startFetch: startFetchHistory,
      setStartFetch: setStartFetchHistory
    });

  //state for modal
  //   const [open, setOpen] = useState(false);
  const [linkPropertiesModal, setLinkPropertyModal] = useState(false);

  //state for show History Model
  //   const [openHistory, setOpenHistory] = useState(false);

  // state for default revenue share
  const [defaultRevenueShare, setDefaultRevenueShare] = useState(0);

  //state for targeting rules that needs to be updated
  const [tempLinkProperty, setTempLinkProperty] = useState("");
  const [tempLinkValue, setTempLinkValue] = useState([]);
  const [tempTargetingRuleIds, setTempTargetingRuleIds] = useState([]);

  // to add or reset rule block
  const newRuleBlock = {
    daily_cap: 0,
    daily_frequency: 100,
    revenue_share_percentage: defaultRevenueShare,
    provider_details: [{ traffic: 100 }],
    group: false
  };

  const newGroupRuleBlock = {
    daily_cap: 0,
    daily_frequency: 100,
    revenue_share_percentage: defaultRevenueShare,
    provider_details: [{ traffic: 100 }],
    group: true
  };

  //state for popup message when weight is less than 100  when round robin is selected
  const [openWeightMessage, setOpenWeightMessage] = useState({
    modal: false,
    type: "draft"
  });

  //   const [targetingHistory, setTargetingHistory] = useState({});
  //   const handleModalClose = () => setOpen(false);
  //   const handleHistoryModalClose = () => setOpenHistory(false);
  const handleWeightMessageModalClose = () =>
    setOpenWeightMessage({ modal: false, type: "draft" });
  const [formOneErrors, setFormOneErrors] = useState({
    publisher: false,
    subID: false,
    clientID: false,
    targetLink: false,
    fallback: false,
    domain: false,
    platform: false,
    searchEngine: false,
    qualityScore: false,
    tagType: false,
    latency: false,
    highPayingKeywords: false
  });

  // duplicated provider links in the backend
  //   const [duplicateProviderLinksDB, setDuplicatedProviderLinksDB] = useState([]);

  // duplicated provider links in the current form page
  const [duplicatedProviderLinks, setDuplicatedProviderLinks] = useState([]);

  const [
    // duplicateAdLinksModal,
    openDuplicateLinksModal
    // closeDuplicateLinksModal
  ] = useModal(false);

  const cleanupFunction = useCallback(() => {
    stableDispatch(clearTargetingForm());
  }, [stableDispatch]);
  const { formTouched, handleFormTouched, clearFormTouched } = useFormTouched({
    cleanupFunction
  });

  //clearning up form touched when the page opens a new targeting ID
  useEffect(() => {
    clearFormTouched();
  }, [clearFormTouched, targetingID]);

  // fetch default revenue share
  useEffect(() => {
    (async () => {
      const revenueData = await ShareRevenue.getShareRevenue();
      setDefaultRevenueShare(revenueData?.data?.share_revenue);
    })();
  }, []);

  const fetchTargeting = useCallback(
    (id, isDuplicated = false) => {
      setLoading(true);
      Publisher.fetchTargeting(id)
        .then((response) => {
          if (response.success) {
            let targeting = response.data.targeting;
            let targetingRules = targeting?.targeting_rules || [];
            if (isDuplicated) {
              targetingRules.map((rule) => {
                delete rule.id;
                return rule;
              });
            }
            if (!targeting) {
              history.push("/target");
              toastr.warning("Oops", "No such targeting found!");
            }
            setPublisher(targeting?.publisher_id);
            setOldRules(
              JSON.stringify(response.data.targeting?.targeting_rules) || ""
            );
            setState((state) => ({
              domain: targeting?.custom_domain,
              cusDomain:
                targeting?.use_custom_domain === "true" ||
                targeting?.use_custom_domain
                  ? true
                  : false,
              cusFallback:
                targeting?.use_custom_fallback === "true" ||
                targeting?.use_custom_fallback
                  ? true
                  : false,
              cusOvercapUrl:
                targeting?.use_custom_overcap_url === "true" ||
                targeting?.use_custom_overcap_url
                  ? true
                  : false,
              clientID: isDuplicated ? null : targeting.id,
              subID: targeting?.sub_id,
              useN: targeting?.n === "true" || targeting?.n ? true : false,
              useClickId:
                targeting?.click_id === "true" || targeting?.click_id
                  ? true
                  : false,
              warmupMode:
                targeting?.warmup_mode === "true" || targeting?.warmup_mode
                  ? true
                  : false,
              useSubId:
                targeting?.use_sid === "true" || targeting?.use_sid
                  ? true
                  : false,
              notes: targeting?.notes ? targeting?.notes : "",
              tagDescription: targeting?.tag_description
                ? targeting?.tag_description
                : "",
              status: targeting?.status,
              targeting: targeting?.targeting_type,
              fallback: targeting?.default_fallback
                ? targeting?.default_fallback
                : "",
              overcapUrl: targeting?.overcap_url
                ? targeting?.overcap_url
                : null,
              updatedAt: targeting?.updatedAt,
              publishedAt: targeting?.publishedAt,
              passReferrer: targeting.pass_referrer,
              IPListProxy: targeting.ip_list_proxy,
              IPListTooManyPerIP: targeting.ip_list_too_many_per_ip,
              minimumChromeVersion: targeting.minimum_chrome_version,
              minimumWindowsVersion: targeting.minimum_windows_version,
              shortKeywords: targeting.short_keywords,
              useHighPayingKeywords:
                !!targeting.high_paying_keywords_percentage,
              useLatency: !!targeting.latency,
              latency: targeting.latency || 1000,
              highPayingKeywords:
                targeting.high_paying_keywords_percentage || 15,
              temporarilyDisableFilters: targeting.disable_filters_temporarily
            }));
            setTargetLink(targeting?.link);
            setActivate(targeting?.is_active);
            setRulesBlockNumber(targeting?.targeting_rules.length);
            setPlatform(targeting?.platform_id);
            setSearchEngine(targeting?.search_engine_id);
            setTagType(targeting?.tag_type_id);
            setQualityScore(targeting?.quality_score);
            setDailyCap(targeting?.daily_cap);
            stableDispatch(updateTargetingRules(targetingRules));
            setLoading(false);
          } else {
            throw new Error(JSON.stringify(response.error));
          }
        })
        .catch((error) => {
          console.trace(error.message);
          setLoading(false);
        });
    },
    [stableDispatch, history]
  );
  //create and dispatch error fields equivalent to targeting rules length for ruleset Form
  useEffect(() => {
    const formErrors = [];
    for (let j = 0; j < targetingRules.length; j++) {
      formErrors.push({
        dailyCap: false,
        trafficOne: false,
        trafficTwo: false,
        providerOne: false,
        providerTwo: false,
        providerOneLink: false,
        providerTwoLink: false,
        dailyFrequency: false,
        revenueSharePercentage: false,
        weightPercentage: false
      });
    }
    stableDispatch(updateTargetingFormErrors(formErrors));
  }, [targetingRules.length, targetingRules, stableDispatch]);

  //changing rules blockset when targeting rules length changes
  useEffect(() => {
    setRulesBlockNumber(targetingRules.length);
  }, [targetingRules]);

  //confirming if user wants to leave
  useEffect(() => {
    if (id && pathname.includes("edit")) {
      setEditing(true);
      setTargetingID(id);
      fetchTargeting(id);
      setHistoryPage(1);
      setStartFetchHistory(true);
    } else if (duplicateId) {
      setEditing(false);
      fetchTargeting(duplicateId, true);
      setLoading(false);
    } else {
      setEditing(false);
      setLoading(false);
    }
  }, [stableDispatch, fetchTargeting, id, pathname, duplicateId]);

  /**
   * CAUTION!! Replaced cid with tid in
   * the link, also cid and tid are used as the
   * same field in the targeting engine. Do not
   * add another cid field in the link unless
   * it's replacing tid's role.
   */
  const handleGenerateLink = useCallback(() => {
    let link = "";
    const linkParameters = {
      // using consigo logic
      ninaya: {
        query: "query",
        publisherId: "pub",
        targetingId: "ch",
        clickId: "click_id",
        queryPlaceholder: "[QUERY]",
        publisherIdPlaceholder: "[PUB]",
        targetingIDPlaceholder: "[CH]",
        clickIdPlaceholder: "[CLICK_ID]"
      },
      consigo: {
        query: "query",
        publisherId: "pub",
        targetingId: "ch",
        clickId: "click_id",
        queryPlaceholder: "[QUERY]",
        publisherIdPlaceholder: "[PUB]",
        targetingIDPlaceholder: "[CH]",
        clickIdPlaceholder: "[CLICK_ID]"
      },
      "candimix-redirect": {
        query: "q",
        publisherId: "pid",
        targetingId: "tid",
        clickId: "click_id",
        queryPlaceholder: "[QUERY]",
        publisherIdPlaceholder: "[PID]",
        targetingIDPlaceholder: "[TID]",
        clickIdPlaceholder: "[CLICK_ID]"
      }
    };
    let linkVarsMap = linkParameters[SYSTEM];
    if (state.cusDomain) {
      if (state.domain !== "") {
        link = state.domain + "?";
      }
    } else {
      link = DEFAULT_DOMAIN + "?";
    }

    if (publisher !== "none" && publisher !== "")
      link = `${link}${linkVarsMap.publisherId}=${publisher}&`;

    link = `${link}${linkVarsMap.targetingId}=${
      state.clientID ? state.clientID : linkVarsMap.targetingIDPlaceholder
    }&`;

    if (state.useClickId)
      link = `${link}${linkVarsMap.clickId}=${linkVarsMap.clickIdPlaceholder}&`;

    link = `${link}${linkVarsMap.query}=${linkVarsMap.queryPlaceholder}`;

    setTargetLink(link);
    return link;
  }, [
    publisher,
    state.clientID,
    state.cusDomain,
    state.domain,
    // state.oID,
    // state.subID,
    state.useClickId,
    // state.useSubId,
    // state.useN,
    SYSTEM,
    DEFAULT_DOMAIN
  ]);
  useEffect(() => {
    handleGenerateLink();
  }, [
    state.subID,
    state.clientID,
    // state.oID,
    state.domain,
    state.useN,
    state.useClickId,
    state.cusDomain,
    state.useSubId,
    handleGenerateLink
  ]);

  const validateForm1 = () => {
    let errorFields = [];
    if (publisher === "none" || publisher === "") {
      errorFields.push("publisher");
    }

    if (!platform) {
      errorFields.push("platform");
    }

    if (!searchEngine) {
      errorFields.push("searchEngine");
    }
    if (!tagType) {
      errorFields.push("tagType");
    }
    if (!qualityScore) {
      errorFields.push("qualityScore");
    }

    if (state.cusDomain) {
      if (!textFieldValid(state.domain) || !validURL(state.domain)) {
        errorFields.push("domain");
      }
    }

    if (
      Number.isNaN(state.latency) ||
      state.latency > 50000 ||
      state.latency < 100
    ) {
      errorFields.push("latency");
    }
    if (
      Number.isNaN(state.highPayingKeywords) ||
      state.highPayingKeywords > 100 ||
      state.highPayingKeywords < 0
    ) {
      errorFields.push("highPayingKeywords");
    }

    if (!textFieldValid(targetLink)) {
      errorFields.push("targetLink");
    }

    if (state.clientID) {
      if (state.clientID < 0) {
        errorFields.push("clientID");
      }
    }

    if (textFieldValid(state.fallback)) {
      if (!validURL(state.fallback)) {
        errorFields.push("fallback");
      }
    }
    if (!errorFields.length > 0) {
      setFormOneErrors({
        publisher: false,
        subID: false,
        clientID: false,
        targetLink: false,
        fallback: false,
        domain: false,
        platform: false,
        searchEngine: false,
        qualityScore: false,
        tagType: false,
        latency: false,
        highPayingKeywords: false
      });
      return true;
    } else {
      let errors = {};
      errorFields.forEach((field) => {
        errors[field] = true;
      });

      setFormOneErrors((formOneErrors) => {
        return {
          ...formOneErrors,
          ...errors
        };
      });
      return false;
    }
  };

  //validating the values of the ruleset Form
  const validateForm2 = () => {
    let isValid = true;
    let weightsValid = true;
    let limitlessDailyCapExists = true;
    const formErrors = [...targetingRulesError];
    let providerLinksSelected = {};

    //validate if targeting Rules was create
    if (targetingRules.length === 0) {
      isValid = false;
      toastr.error("Oops", "Please add targeting rules!!!");
    }

    //validating that the sum of all the weights does not exceed 100
    if (state.targeting === TARGETING_TYPES.ROUND_ROBIN) {
      if (!areTheWeightsValid(targetingRules)) {
        isValid = false;
        weightsValid = false;
        setLogicError(true);
        toastr.warning(
          "Oops!",
          "Total Weights(%) exceed 100% / Enabled rule with 0 value for weight!"
        );
      }
    }

    if (state.targeting === TARGETING_TYPES.WATERFALL) {
      if (!isThereLimitlessDailyCap(targetingRules)) {
        isValid = false;
        limitlessDailyCapExists = false;
        setLogicError(true);
        toastr.warning(
          "Oops!",
          "Daily Cap of atleast one rule must be default value(0)!"
        );
      }
    }

    for (let i = 0; i < targetingRules.length; i++) {
      let rule = targetingRules[i];
      let ruleDisabled = rule?.disabled || false;
      let formErrorObject = formErrors[i];
      if (
        rule.daily_cap === "" ||
        isNaN(rule.daily_cap) ||
        parseInt(rule.daily_cap) < 0
      ) {
        isValid = false;
        formErrorObject.dailyCap = true;
      }

      if (state.targeting === TARGETING_TYPES.WATERFALL) {
        if (!limitlessDailyCapExists) {
          formErrorObject.dailyCap = true;
        }
      }

      if (
        rule.revenue_share_percentage === "" ||
        isNaN(rule.revenue_share_percentage) ||
        parseInt(rule.revenue_share_percentage) < 0 ||
        parseInt(rule.revenue_share_percentage) > 100
      ) {
        isValid = false;
        formErrorObject.revenue_share_percentage = true;
      }

      //validating that weights are valid
      if (state.targeting === TARGETING_TYPES.ROUND_ROBIN) {
        if (
          !ruleDisabled &&
          (rule.daily_frequency <= 0 || rule.daily_frequency > 100)
        ) {
          isValid = false;
          formErrorObject.dailyFrequency = true;
        }
        if (
          rule.daily_frequency === undefined ||
          rule.daily_frequency === null ||
          isNaN(parseFloat(rule.daily_frequency))
        ) {
          isValid = false;
          formErrorObject.dailyFrequency = true;
        }
        if (!weightsValid) {
          formErrorObject.dailyFrequency = true;
        }
      }

      if (!rule.provider_details || rule.provider_details.length === 0) {
        isValid = false;
        formErrorObject.trafficOne = true;
      } else {
        if (rule.provider_details.length === 2) {
          //case when two providers are selected
          if (
            !rule.provider_details[0].traffic ||
            isNaN(rule.provider_details[0].traffic) ||
            parseInt(rule.provider_details[0].traffic) <= 0
          ) {
            isValid = false;
            formErrorObject.trafficOne = true;
          }
          let providerOneLink = rule.provider_details[0].provider_link;
          if (rule.group) {
          } else if (
            !rule.group &&
            (!rule.provider_details[0].provider_id ||
              rule.provider_details[0].provider_id === "")
          ) {
            isValid = false;
            formErrorObject.providerOne = true;
          }
          if (providerOneLink === "") {
            isValid = false;
            formErrorObject.providerOneLink = true;
          } else if (!ruleDisabled) {
            providerLinksSelected[providerOneLink] =
              providerLinksSelected.hasOwnProperty(providerOneLink)
                ? providerLinksSelected[providerOneLink] + 1
                : 1;
          }
          if (
            !rule.provider_details[1].traffic ||
            isNaN(rule.provider_details[1].traffic) ||
            parseInt(rule.provider_details[1].traffic) <= 0
          ) {
            isValid = false;
            formErrorObject.trafficTwo = true;
          }
          if (
            !rule.provider_details[1].provider_id ||
            rule.provider_details[1].provider_id === ""
          ) {
            isValid = false;
            formErrorObject.providerTwo = true;
          }
          let providerTwoLink = rule.provider_details[1].provider_link;
          if (providerTwoLink === "") {
            isValid = false;
            formErrorObject.providerTwoLink = true;
          } else if (!ruleDisabled) {
            providerLinksSelected[providerTwoLink] =
              providerLinksSelected.hasOwnProperty(providerTwoLink)
                ? providerLinksSelected[providerTwoLink] + 1
                : 1;
          }
          if (
            parseInt(rule.provider_details[1].traffic) +
              parseInt(rule.provider_details[0].traffic) !==
            100
          ) {
            isValid = false;
            formErrorObject.trafficOne = true;
          }
        } else {
          if (
            !rule.provider_details[0].traffic ||
            isNaN(rule.provider_details[0].traffic) ||
            parseInt(rule.provider_details[0].traffic) !== 100
          ) {
            isValid = false;
            formErrorObject.trafficOne = true;
          }
          let providerOneLink = rule.group
            ? rule.provider_details[0].advertiser_link_group_id
            : rule.provider_details[0].provider_link;

          if (rule.group) {
          } else if (
            !rule.group &&
            (!rule.provider_details[0].provider_id ||
              rule.provider_details[0].provider_id === "")
          ) {
            isValid = false;
            formErrorObject.providerOne = true;
          }

          if (providerOneLink === "") {
            isValid = false;
            formErrorObject.providerOneLink = true;
          } else if (!ruleDisabled) {
            providerLinksSelected[providerOneLink] =
              providerLinksSelected.hasOwnProperty(providerOneLink)
                ? providerLinksSelected[providerOneLink] + 1
                : 1;
          }
        }
      }
    }

    // validating for duplicates in provider links for rules that are not disabled
    const activeNormalRules = targetingRules.filter(
      (rule) => !rule.disabled && !rule.group
    );
    const activeGroupRules = targetingRules.filter(
      (rule) => !rule.disabled && rule.group
    );

    let duplicatedProviderLinks = [];
    activeGroupRules.forEach((rule) => {
      const selectedAdvertiserLinkGroupId =
        rule.provider_details[0].advertiser_link_group_id || null;
      if (selectedAdvertiserLinkGroupId) {
        const matches = activeGroupRules.filter(
          (x) =>
            x.provider_details[0].advertiser_link_group_id ===
            selectedAdvertiserLinkGroupId
        );
        if (matches.length > 1) {
          duplicatedProviderLinks.push(selectedAdvertiserLinkGroupId);
          isValid = false;
          setLogicError(true);
        }
      }
    });
    activeNormalRules.forEach((rule) => {
      const selectedAdvertiserLinkId =
        rule.provider_details[0].advertiser_link_group_id || null;
      if (selectedAdvertiserLinkId) {
        const matches = activeNormalRules.filter(
          (x) =>
            x.provider_details[0].advertiser_link_group_id ===
            selectedAdvertiserLinkId
        );
        if (matches.length > 1) {
          duplicatedProviderLinks.push(selectedAdvertiserLinkId);
          isValid = false;
          setLogicError(true);
        }
      }
    });

    setDuplicatedProviderLinks(duplicatedProviderLinks);
    stableDispatch(updateTargetingFormErrors(formErrors));
    return isValid;
  };

  /**
   *  This function is currently not used as the feature was change to let admin use same link
   *  @returns isValid;
   *  @description validates if the advertiser links selected here is duplicate with any publisher  in the backend
   */
  const validateAdvertiserLinks = async () => {
    return true;
  };

  /**
   * @description opens the modal showing the duplicated advertiser links in other tags using the value saved in state
   */
  const openDuplicatedAdvertiserLinksModal = () => {
    openDuplicateLinksModal();
  };

  //   const closeDuplicatedAdvertiserLinksModal = () => {
  //     setDuplicatedProviderLinksDB([]);
  //     closeDuplicateLinksModal();
  //   };

  const fetchPublishers = () => {
    Publisher.fetchPublishers({
      order_by: "id",
      order_direction: "ASC",
      size: DEFAULT_FETCH_PARAM_SIZE
    })
      .then((response) => {
        if (response.success) {
          const data = response.data.publishers;
          const publishers = [];
          let inactivePublishers = [];
          for (let p of data) {
            let isInactive = p.status === "inactive";
            isInactive && inactivePublishers.push(p.id);
            publishers.push({
              label: p.name,
              value: p.id,
              disabled: isInactive
            });
          }
          setDisabledPublishers(inactivePublishers);
          setPublishers(publishers);
        } else {
          throw new Error(JSON.stringify(response.error));
        }
      })
      .catch((error) => {
        console.trace(error.message);
      });
  };

  useEffect(() => {
    if (!settingsObject?.default_domain || !settingsObject?.system_fallback) {
      Setting.fetchSettings()
        .then((response) => {
          if (response.success) {
            const settings = response.data?.settings || [];
            let settingsObject = {};
            settings.forEach((setting) => {
              settingsObject[setting.key] = setting.value;
            });
            stableDispatch(updateSettingsObject(settingsObject));
            stableDispatch(updateDefaultSettings(settings));
            setDEFAULT_DOMAIN(settingsObject?.default_domain);
            setDEFAULT_FALLBACK(settingsObject?.system_fallback);
            if (
              !settingsObject?.default_domain ||
              !settingsObject?.system_fallback
            ) {
              toastr.warning("Oops!", "Default Domain not set");
              history.push("/settings");
            }
          } else {
            throw new Error(JSON.stringify(response.error));
          }
        })
        .catch((error) => {
          toastr.error("Opps", "Cannot load default settings!!!");
          console.trace(error.message);
        });
    }
    fetchPublishers();
  }, [history, settingsObject, stableDispatch, DEFAULT_DOMAIN]);

  const addMoreRulesBlock = (type) => {
    let currentTargetingRules = [...targetingRules];
    if (type === "group") {
      currentTargetingRules.push(newGroupRuleBlock);
    } else {
      currentTargetingRules.push(newRuleBlock);
    }
    stableDispatch(updateTargetingRules(currentTargetingRules));
  };

  const handlePublisherChange = (value) => {
    if (disabledPublishers.includes(value)) {
      return;
    }
    setPublisher(value);
    handleFormTouched();
  };

  // const { changeStatusByIndices } = useHandleFormValueChange({
  //     initialTargeting,
  //     targetingRules,
  //     handleFormTouched,
  //     oldRules,
  //     setDailyCap,
  //     isWeightFieldTouched,
  //     setIsWeightFieldTouched
  // }); // change disable status by Indices

  // const trackIndicesofRemovedProperty = (
  //     removedProperty,
  //     property,
  //     rule,
  //     index,
  //     collectiveIndex
  // ) => {
  //     let provider_detail = Array.isArray(rule.provider_details)
  //         ? rule.provider_details[0]
  //         : {};

  //     switch (property) {
  //         case "platform":
  //             if (provider_detail.platform_id === removedProperty)
  //                 collectiveIndex.push(index);
  //             break;

  //         case "searchEngine":
  //             if (provider_detail.search_engine_id === removedProperty)
  //                 collectiveIndex.push(index);
  //             break;

  //         case "tagType":
  //             if (provider_detail.tag_type_id === removedProperty)
  //                 collectiveIndex.push(index);
  //             break;

  //         case "qualityScore":
  //             if (provider_detail.quality_score === removedProperty)
  //                 collectiveIndex.push(index);
  //             break;

  //         default:
  //             break;
  //     }

  //     return collectiveIndex;
  // };

  const handleLinkPropertiesChange = (property, value) => {
    switch (property) {
      case "platform":
        handlePlatformChange(value);
        break;

      case "searchEngine":
        handleSearchEngineChange(value);
        break;

      case "tagType":
        handleTagTypeChange(value);
        break;

      case "qualityScore":
        handleQualityScoreChange(value);
        break;

      default:
        break;
    }
  };

  const checkForDisabledRules = (property, oldValue, value) => {
    let showModalWarning = false;
    if (oldValue !== null && Array.isArray(oldValue)) {
      let diff = oldValue.filter((item) => !value.includes(item));
      let tempTargetingRuleIds = [];

      if (diff.length) {
        switch (property) {
          case "platform":
            tempTargetingRuleIds = targetingRules
              .filter((rule) =>
                diff.includes(rule.provider_details[0].platform_id)
              )
              .map((rule) => rule.provider_details[0].provider_link);
            break;
          case "searchEngine":
            tempTargetingRuleIds = targetingRules
              .filter((rule) =>
                diff.includes(rule.provider_details[0].search_engine_id)
              )
              .map((rule) => rule.provider_details[0].provider_link);
            break;
          case "tagType":
            tempTargetingRuleIds = targetingRules
              .filter((rule) =>
                diff.includes(rule.provider_details[0].tag_type_id)
              )
              .map((rule) => rule.provider_details[0].provider_link);
            break;
          case "qualityScore":
            tempTargetingRuleIds = targetingRules
              .filter((rule) =>
                diff.includes(rule.provider_details[0].quality_score)
              )
              .map((rule) => rule.provider_details[0].provider_link);
            break;
          default:
            break;
        }
      }
      tempTargetingRuleIds.length > 0
        ? (showModalWarning = true)
        : (showModalWarning = false);
      setTempTargetingRuleIds(tempTargetingRuleIds);
      setLinkPropertyModal(showModalWarning);
    }

    if (!showModalWarning) {
      handleLinkPropertiesChange(property, value);
    } else {
      setTempLinkProperty(property);
      setTempLinkValue(value);
    }
  };

  const resetLinkPropertyChange = () => {
    setTempLinkProperty("");
    setTempLinkValue([]);
    setTempTargetingRuleIds([]);
    setLinkPropertyModal(false);
  };

  const confirmLinkPropertyChange = () => {
    // disable targeting rules after link property removal
    targetingRules.map((rule) => {
      if (
        tempTargetingRuleIds.includes(rule.provider_details[0].provider_link)
      ) {
        rule.disabled = true;
      }
      return rule;
    });
    setDailyCap(calculateTotalDailyCap(targetingRules));
    const updatedTargetingRules =
      updateTargetingRulesBeforeDispatch(targetingRules);
    stableDispatch(updateTargetingRules(updatedTargetingRules));
    handleLinkPropertiesChange(tempLinkProperty, tempLinkValue);
    resetLinkPropertyChange();
  };

  const handlePlatformChange = (value) => {
    setPlatform(isArray(value) && value.length > 0 ? value : null);
    handleFormTouched();
  };
  const handleSearchEngineChange = (value) => {
    setSearchEngine(isArray(value) && value.length > 0 ? value : null);
    handleFormTouched();
  };
  const handleTagTypeChange = (value) => {
    setTagType(isArray(value) && value.length > 0 ? value : null);
    const TYPE_IN_ID =
      tagTypes.find((x) => x.label === "TYPE-IN")?.value || null;
    const passRefererArr =
      tagTypes
        .filter((x) => ["N2S", "AFD"].includes(x.label))
        .map((item) => item.value) || [];

    if (
      value &&
      isArray(value) &&
      value.length === 1 &&
      value.some((item) => passRefererArr.includes(item))
    ) {
      // if type is N2S or AFD then disable all filters and enable only pass referrer
      const newState = {
        useClickId: false,
        warmupMode: false,
        passReferrer: true,
        IPListProxy: false,
        IPListTooManyPerIP: false,
        minimumChromeVersion: false,
        minimumWindowsVersion: false,
        shortKeywords: false,
        useLatency: false,
        useHighPayingKeywords: false,
        temporarilyDisableFilters: false
      };
      setState({ ...state, ...newState });
    } else if (
      value &&
      isArray(value) &&
      value.length === 1 &&
      value.includes(TYPE_IN_ID)
    ) {
      setState({ ...state, useHighPayingKeywords: true });
    } else {
      setState({ ...state, useHighPayingKeywords: false, passReferrer: false });
    }
    handleFormTouched();
  };

  const handleQualityScoreChange = (value) => {
    setQualityScore(isArray(value) && value.length > 0 ? value : null);
    let warmupModeChange = false;
    const passRefererArr =
      tagTypes
        .filter((x) => ["N2S", "AFD"].includes(x.label))
        .map((item) => item.value) || [];

    // if N2S or AFD tag type is selected then all filters are disabled
    if (
      tagType &&
      isArray(tagType) &&
      tagType.length === 1 &&
      tagType.some((item) => passRefererArr.includes(item))
    ) {
      setState({ ...state, warmupMode: false });
      warmupModeChange = true;
    }

    if (!editing && !warmupModeChange) {
      if (value.length) {
        if (value.includes("low") || value.includes("mid"))
          setState({ ...state, warmupMode: false });
        else setState({ ...state, warmupMode: true });
      } else {
        setState({ ...state, warmupMode: false });
      }
    }
    handleFormTouched();
  };

  const handleDailyCapChange = (value) => {
    setDailyCap(value);
    handleFormTouched();
  };

  const handleChange = (event) => {
    handleFormTouched();
    setFormOneErrors({
      publisher: false,
      subID: false,
      clientID: false,
      targetLink: false,
      fallback: false,
      domain: false,
      dailyCap: false,
      platform: false,
      search_engine: false,
      quality_score: false,
      tag_type: false,
      highPayingKeywords: false,
      latency: false
    });

    if (event.target.name === "subID") {
      setState({
        ...state,
        [event.target.name]: event.target.value,
        useSubId: false
      });
      return;
    }
    //clearing subID if useSubID is true
    if (event.target.name === "useSubId") {
      setState({
        ...state,
        [event.target.name]: event.target.checked,
        subID: null
      });
      return;
    }
    if (event.target.name === "warmupMode") {
      setState({
        ...state,
        [event.target.name]: event.target.checked
      });
      return;
    }

    //not chaning values if negative values are entered
    //for OID and Client ID
    //if (["oID", "clientID"].includes(event.target.name))
    if (["clientID"].includes(event.target.name)) {
      if (parseInt(event.target.value) < 0) {
        setFormOneErrors({
          ...formOneErrors,
          [event.target.name]: true
        });
        return;
      }
    }
    if (["dailyCap"].includes(event.target.name)) {
      if (parseInt(event.target.value) < 0) {
        setFormOneErrors({
          ...formOneErrors,
          [event.target.name]: true
        });
        return;
      } else {
        handleDailyCapChange(event.target.value);
      }
    }
    if (
      [
        "cusDomain",
        "useN",
        "useClickId",
        "cusFallback",
        "cusOvercapUrl",
        "passReferrer",
        "IPListProxy",
        "IPListTooManyPerIP",
        "minimumChromeVersion",
        "minimumWindowsVersion",
        "shortKeywords",
        "useLatency",
        "useHighPayingKeywords",
        "temporarilyDisableFilters"
      ].includes(event.target.name)
    ) {
      setState({ ...state, [event.target.name]: event.target.checked });
    } else {
      setState({ ...state, [event.target.name]: event.target.value });
    }
  };

  const handleActivate = (event) => {
    handleFormTouched();
    setActivate(event.target.checked);
  };

  //type = "draft" or "publish"
  const handleSave = async (type) => {
    setLogicError(false);
    //validating forms
    let isFormOneValid = validateForm1();
    let isFormTwoValid = validateForm2();
    if (!isFormOneValid || !isFormTwoValid) {
      toastr.info("Please check all the required field values!");
      window.scrollTo({
        top: 100,
        left: 100,
        behavior: "smooth"
      });
      return;
    }
    let isAdvertiserLinksValid = await validateAdvertiserLinks();
    if (!isAdvertiserLinksValid) {
      openDuplicatedAdvertiserLinksModal();
      return;
    }
    if (targetLink !== "") {
      setShowPrompt(false);
      clearFormTouched();
      const data = getFormData(type);
      if (typeof data["sub_id"] === "string") {
        if (data["sub_id"].trim() === "") {
          data["sub_id"] = null;
        }
      }
      if (typeof data["client_id"] === "string") {
        if (data["client_id"].trim() === "") {
          data["client_id"] = null;
        }
      }

      data["targeting_rules"] = formatTargetDataForSave(
        targetingRules,
        null,
        state.targeting,
        activate
      );
      setSendingFormData(true);
      Publisher.saveTargeting(data)
        .then((response) => {
          if (response.success) {
            if (type === "draft") {
              // set edit to true, add targeting id and then update
              setEditing(true);
              let savedTargeting = response.data.targeting;
              setTargetingID(savedTargeting.id);
              fetchTargeting(savedTargeting.id);
              toastr.success(
                "Success",
                "This publisher link is ready to be published!"
              );
            } else {
              dispatch(clearTargetingForm());
              history.push("/target");
              toastr.success("Success", "Publisher Link published!");
            }
            setSendingFormData(false);
          } else {
            throw new Error(JSON.stringify(response.error));
          }
        })
        .catch((error) => {
          console.trace(error.message);
          toastr.error("Oops!", JSON.parse(error.message).message);
          setSendingFormData(false);
        });
    }
  };

  //type = "draft" or "publish"
  const handleUpdate = async (type) => {
    setLogicError(false);
    //validating forms
    let isFormOneValid = validateForm1();
    let isFormTwoValid = validateForm2();
    if (!isFormOneValid || !isFormTwoValid) {
      toastr.info("Please check all the required field values!");
      window.scrollTo({
        top: 100,
        left: 100,
        behavior: "smooth"
      });
      return;
    }
    let isAdvertiserLinksValid = await validateAdvertiserLinks();
    if (!isAdvertiserLinksValid) {
      openDuplicatedAdvertiserLinksModal();
      return;
    }
    if (targetLink !== "") {
      setShowPrompt(false);
      clearFormTouched();
      const data = getFormData(type);
      if (typeof data["sub_id"] === "string") {
        if (data["sub_id"].trim() === "") {
          data["sub_id"] = null;
        }
      }
      if (typeof data["client_id"] === "string") {
        if (data["client_id"].trim() === "") {
          data["client_id"] = null;
        }
      }

      let targeting_id = targetingID;
      data["targeting_rules"] = formatTargetDataForSave(
        targetingRules,
        targeting_id,
        state.targeting,
        activate
      );
      data["targeting_rules"] = data["targeting_rules"].concat(
        deletedTargetingRules
      );

      setSendingFormData(true);
      //make api call and update here
      Publisher.updateTargeting(targeting_id, data)
        .then((response) => {
          if (response.success) {
            if (type === "draft") {
              fetchTargeting(targeting_id);
              setHistoryPage(1);
              setStartFetchHistory(true);
              toastr.success(
                "Success",
                "This publisher link is ready to be published!"
              );
            } else {
              dispatch(clearTargetingForm());
              history.push("/target");
              toastr.success("Success", "Publisher link updated!");
            }
            setSendingFormData(false);
          } else {
            throw new Error(JSON.stringify(response.error));
          }
        })
        .catch((error) => {
          console.trace(error.message);
          toastr.error("Oops!", JSON.parse(error.message).message);
          setSendingFormData(false);
        });
    }
  };

  const getFormData = (type) => {
    let fallback;
    if (!state.cusFallback) {
      fallback = DEFAULT_FALLBACK;
    } else {
      fallback = state.fallback.trim() === "" ? null : state.fallback;
    }

    const data = {
      publisher_id: publisher,
      custom_domain: state.cusDomain ? state.domain : DEFAULT_DOMAIN,
      use_custom_domain: state.cusDomain,
      use_custom_fallback: state.cusFallback,
      use_custom_overcap_url: state.cusOvercapUrl,
      overcap_url: state.overcapUrl,
      is_active: activate,
      sub_id: state.subID,
      client_id: state.clientID,
      use_sid: state.useSubId,
      warmup_mode: state.warmupMode,
      status: type,
      n: state.useN,
      click_id: state.useClickId,
      default_fallback: fallback,
      targeting_type: state.targeting,
      link: handleGenerateLink(),
      notes: state.notes.trim() === "" ? null : state.notes,
      tag_description:
        state.tagDescription.trim() === "" ? null : state.tagDescription,
      platform_id: platform || null,
      search_engine_id: searchEngine || null,
      tag_type_id: tagType || null,
      quality_score: qualityScore || null,
      pass_referrer: state.passReferrer,
      ip_list_proxy: state.IPListProxy,
      ip_list_too_many_per_ip: state.IPListTooManyPerIP,
      minimum_chrome_version: state.minimumChromeVersion,
      minimum_windows_version: state.minimumWindowsVersion,
      short_keywords: state.shortKeywords,
      latency: state.useLatency ? state.latency : null,
      high_paying_keywords_percentage: state.useHighPayingKeywords
        ? state.highPayingKeywords
        : null,
      disable_filters_temporarily: state.temporarilyDisableFilters
        ? state.temporarilyDisableFilters
        : null
    };
    return data;
  };

  const handleCopyToClipboard = () => {
    if (textFieldValid(targetLink)) {
      copyToClipboard(targetLink);
      toastr.success("Success", "Link copied!");
    }
  };

  const handleCheckTag = (link) => {
    let checkedlink = link.match(/^http[s]?:\/\//) ? link : "http://" + link;
    window.open(checkedlink, "_blank");
  };

  /**
   * Returns a Boolean Value on whether to open or not the modal for weight<100 msg!
   *
   * @returns [True] if the follwing conditions are met
   *  a) The Targeting is of Round Robin Type
   *  b) If atleast one of the rule is not inactive (one rule exists such that rule.disabled === false)
   *  c) Sum is less than 100
   */
  function shouldOpenWeightMessage() {
    //state to track if we should open the popup message for weight
    let openMessage = false;
    let openMessageConditions = {
      IS_ROUND_ROBIN: state.targeting === TARGETING_TYPES.ROUND_ROBIN,
      ACTIVE_RULES_EXIST: getNumberOfActiveRules(targetingRules) > 0
    };
    if (
      openMessageConditions.IS_ROUND_ROBIN &&
      openMessageConditions.ACTIVE_RULES_EXIST
    ) {
      let { sum } = sumOfInputedWeights(targetingRules);
      if (sum < 100) {
        openMessage = true;
      }
    }
    return openMessage;
  }

  const handleSaveUpdate = (type) => {
    !editing ? handleSave(type) : handleUpdate(type);
    if (type === "draft") setSaveDraftStatus(false);
  };

  const handleFormButtonClick = (type) => {
    let openMessage = shouldOpenWeightMessage();
    if (openMessage) {
      setOpenWeightMessage({
        modal: true,
        type
      });
    } else {
      if (type === "draft") {
        setSaveDraftStatus(true);
      } else {
        handleSaveUpdate(type);
      }
    }
  };

  const handleFormSubmit = (type) => {
    !editing ? handleSave(type) : handleUpdate(type);
  };

  if (loading) return <LoadingSpinner />;

  // const getDefaultRevenueShare = async () => {
  //     return await ShareRevenue.getShareRevenue();
  // };

  return (
    <TagTypeContext.Provider value={tagTypes}>
      <PlatformContext.Provider value={platforms}>
        <SearchEngineContext.Provider value={searchEngines}>
          <div className="targeting-form">
            {!sendingFormData ? (
              <>
                <div className="targeting-form__header">
                  <ContentHeader
                    icon={<TargetIcon fontSize="inherit" color="primary" />}
                    title="Publisher Link"
                    additionalComponent={
                      <FormAddtionalHeaders
                        targetingID={targetingID}
                        publisherChannelStats={publisherChannelStats}
                        activityLog={latestHistoryData}
                        historyPage={historyPage}
                        setHistoryPage={setHistoryPage}
                        historyLimit={historyLimit}
                        totalHistoryCount={totalHistoryCount}
                        isLatestHistoryLoading={isLatestHistoryLoading}
                        setStartFetchHistory={setStartFetchHistory}
                      />
                    }
                  />
                </div>
                <div className="targeting-form__publisher-section">
                  <div className="targeting-form__publisher-section__top">
                    <div className="targeting-form__client-id">
                      <FormControl>
                        <TextField
                          label="Pub Link ID"
                          variant="outlined"
                          disabled
                          name="clientID"
                          value={state.clientID == null ? "" : state.clientID}
                          onChange={handleChange}
                          error={formOneErrors.clientID}
                          autoFocus={formOneErrors.clientID}
                          type="number"
                        />
                        {formOneErrors.clientID && (
                          <FormHelperText className="error-text">
                            Positive Number Required
                          </FormHelperText>
                        )}
                      </FormControl>
                    </div>
                    <div className="targeting-form__publisher">
                      <AutoCompleteSelect
                        selected={publisher ? publisher : ""}
                        data={publishers || []}
                        handleChange={(value) => handlePublisherChange(value)}
                        label={"Publisher"}
                        showValueInLabel={true}
                        enableMultiple={false}
                        withDotsForStatus={true}
                        cannotSelectDisabled={false}
                        disabled={editing}
                        error={formOneErrors.publisher}
                      />
                      {formOneErrors.publisher && (
                        <FormHelperText className="error-text">
                          Publisher is required!
                        </FormHelperText>
                      )}
                    </div>
                    <div className="targeting-form__cus-domain">
                      <div>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={state.cusDomain}
                              onChange={handleChange}
                              icon={
                                <CheckBoxOutlineBlankIcon fontSize="small" />
                              }
                              checkedIcon={<CheckBoxIcon fontSize="small" />}
                              name="cusDomain"
                            />
                          }
                          label="Use Custom Domain"
                        />
                        {formOneErrors.domain && (
                          <FormHelperText>
                            <span className="error-text">
                              Domain is Required!
                            </span>
                          </FormHelperText>
                        )}
                      </div>
                      <div>
                        <FormControl fullWidth>
                          <TextField
                            name="domain"
                            variant="outlined"
                            value={
                              !state.cusDomain ? DEFAULT_DOMAIN : state.domain
                            }
                            onChange={handleChange}
                            error={formOneErrors.domain}
                            autoFocus={formOneErrors.domain}
                            disabled={!state.cusDomain}
                          />
                          {formOneErrors.domain && (
                            <FormHelperText>
                              <span className="error-text">
                                Domain Required
                              </span>
                            </FormHelperText>
                          )}
                        </FormControl>
                      </div>
                    </div>
                    <div className="targeting-form__active">
                      <FormControlLabel
                        control={
                          <Switch
                            checked={activate}
                            onChange={handleActivate}
                            name="checkedA"
                          />
                        }
                        label="Active"
                      />
                    </div>
                  </div>
                  <div className="targeting-form__publisher-section__upper-mid">
                    <Grid container spacing={3}>
                      <Grid item md={9}>
                        <Grid
                          container
                          spacing={4}
                          className="link__properties-container"
                        >
                          <Grid item lg={1} md={3} sm={6} xs={12}>
                            <div
                              style={{
                                marginTop: "17px",
                                color: "#949494"
                              }}
                            >
                              <label>Link Properties: </label>
                            </div>
                          </Grid>
                          <Grid item lg={11} md={9} xs={12} sm={12}>
                            <Grid container spacing={3}>
                              <Grid item xs={12} sm={4} md={4}>
                                <AutoCompleteSelect
                                  name="platform"
                                  selected={platform}
                                  data={platforms || []}
                                  handleChange={(value) =>
                                    checkForDisabledRules(
                                      "platform",
                                      platform,
                                      value
                                    )
                                  }
                                  label={"Platform"}
                                  showValueInLabel={true}
                                  enableMultiple={true}
                                  withDotsForStatus={false}
                                  error={formOneErrors.platform}
                                />
                                {formOneErrors.platform && (
                                  <FormHelperText>
                                    <span className="error-text">
                                      Platform is Required!
                                    </span>
                                  </FormHelperText>
                                )}
                              </Grid>
                              <Grid item xs={12} sm={4} md={4}>
                                <AutoCompleteSelect
                                  name="searchEngine"
                                  selected={searchEngine}
                                  data={searchEngines || []}
                                  handleChange={(value) =>
                                    checkForDisabledRules(
                                      "searchEngine",
                                      searchEngine,
                                      value
                                    )
                                  }
                                  label={"Search Engine"}
                                  showValueInLabel={true}
                                  enableMultiple={true}
                                  withDotsForStatus={false}
                                  error={formOneErrors.searchEngine}
                                />
                                {formOneErrors.searchEngine && (
                                  <FormHelperText>
                                    <span className="error-text">
                                      Search engine is Required!
                                    </span>
                                  </FormHelperText>
                                )}
                              </Grid>
                              <Grid item xs={12} sm={4} md={4}>
                                <AutoCompleteSelect
                                  name="tagType"
                                  selected={tagType}
                                  data={tagTypes}
                                  handleChange={(value) =>
                                    checkForDisabledRules(
                                      "tagType",
                                      tagType,
                                      value
                                    )
                                  }
                                  label={"Traffic Type"}
                                  showValueInLabel={true}
                                  enableMultiple={true}
                                  withDotsForStatus={false}
                                  error={formOneErrors.tagType}
                                />
                                {formOneErrors.tagType && (
                                  <FormHelperText>
                                    <span className="error-text">
                                      Tag type is Required!
                                    </span>
                                  </FormHelperText>
                                )}
                              </Grid>
                              <Grid item xs={12} sm={4} md={4}>
                                <AutoCompleteSelect
                                  name="qualityScore"
                                  selected={qualityScore}
                                  data={QualityScoreDropDown || []}
                                  handleChange={(value) =>
                                    checkForDisabledRules(
                                      "qualityScore",
                                      qualityScore,
                                      value
                                    )
                                  }
                                  label={"Quality Score"}
                                  showValueInLabel={false}
                                  enableMultiple={true}
                                  withDotsForStatus={false}
                                  error={formOneErrors.qualityScore}
                                />
                                {formOneErrors.qualityScore && (
                                  <FormHelperText>
                                    <span className="error-text">
                                      Quality score is Required!
                                    </span>
                                  </FormHelperText>
                                )}
                              </Grid>
                              <Grid item xs={12} sm={4} md={4}>
                                <FormControl fullWidth>
                                  <TextField
                                    label="Daily Cap"
                                    variant="outlined"
                                    name="dailyCap"
                                    value={dailyCap}
                                    onChange={handleChange}
                                    error={formOneErrors.dailyCap}
                                    autoFocus={formOneErrors.dailyCap}
                                    type="number"
                                    disabled={true}
                                  />
                                  {formOneErrors.clientID && (
                                    <FormHelperText className="error-text">
                                      Positive Number Required
                                    </FormHelperText>
                                  )}
                                </FormControl>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>

                      <Grid item md={3} sm={12}>
                        <div
                          className="targeting-form__update-info"
                          style={{
                            textAlign: "left"
                          }}
                        >
                          <p style={{ textAlign: "left" }}>
                            Status:{" "}
                            {state?.status ? state?.status.toUpperCase() : ""}
                          </p>
                          <p>
                            Last Updated (UTC) :<br />
                            {state.updatedAt &&
                              moment(state.updatedAt).utc().format("lll")}
                          </p>
                          <p>
                            Last Published (UTC) : <br />
                            {state.publishedAt &&
                              moment(state.publishedAt).utc().format("lll")}
                          </p>
                        </div>
                      </Grid>
                    </Grid>
                    <SimpleAccordion
                      header="Filters"
                      key="publisher-link-filters"
                      defaultExpanded={true}
                    >
                      <Grid container spacing={3}>
                        <Grid item xs={6} sm={3} md={3}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={state.IPListProxy}
                                onChange={handleChange}
                                icon={
                                  <CheckBoxOutlineBlankIcon fontSize="small" />
                                }
                                checkedIcon={<CheckBoxIcon fontSize="small" />}
                                name="IPListProxy"
                              />
                            }
                            label="IP List - Proxy"
                          />
                        </Grid>
                        <Grid item xs={6} sm={3} md={3}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={state.IPListTooManyPerIP}
                                onChange={handleChange}
                                icon={
                                  <CheckBoxOutlineBlankIcon fontSize="small" />
                                }
                                checkedIcon={<CheckBoxIcon fontSize="small" />}
                                name="IPListTooManyPerIP"
                              />
                            }
                            label="IP List - Too many per IP"
                          />
                        </Grid>
                        <Grid item xs={6} sm={3} md={3}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={state.minimumChromeVersion}
                                onChange={handleChange}
                                icon={
                                  <CheckBoxOutlineBlankIcon fontSize="small" />
                                }
                                checkedIcon={<CheckBoxIcon fontSize="small" />}
                                name="minimumChromeVersion"
                              />
                            }
                            label="Minimum chrome version"
                          />
                        </Grid>
                        <Grid item xs={6} sm={3} md={3}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={state.minimumWindowsVersion}
                                onChange={handleChange}
                                icon={
                                  <CheckBoxOutlineBlankIcon fontSize="small" />
                                }
                                checkedIcon={<CheckBoxIcon fontSize="small" />}
                                name="minimumWindowsVersion"
                              />
                            }
                            label="Minimum windows version"
                          />
                        </Grid>
                        <Grid item xs={6} sm={3} md={3}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={state.shortKeywords}
                                onChange={handleChange}
                                icon={
                                  <CheckBoxOutlineBlankIcon fontSize="small" />
                                }
                                checkedIcon={<CheckBoxIcon fontSize="small" />}
                                name="shortKeywords"
                              />
                            }
                            label="Short Keywords"
                          />
                        </Grid>
                        <Grid
                          item
                          xs={6}
                          sm={3}
                          md={3}
                          style={
                            {
                              // display: 'flex',
                              // flexDirection: 'row',
                            }
                          }
                        >
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={state.useLatency}
                                onChange={handleChange}
                                icon={
                                  <CheckBoxOutlineBlankIcon fontSize="small" />
                                }
                                checkedIcon={<CheckBoxIcon fontSize="small" />}
                                name="useLatency"
                              />
                            }
                            label="Latency(ms)"
                          />
                          <FormControl style={{ marginLeft: "2rem" }}>
                            <TextField
                              name="latency"
                              type="number"
                              value={state.latency}
                              onChange={handleChange}
                              error={formOneErrors.latency}
                              autoFocus={formOneErrors.latency}
                              disabled={!state.useLatency}
                              style={{
                                maxWidth: "8ch"
                              }}
                            />
                            {formOneErrors.latency && (
                              <FormHelperText className="error-text">
                                Invalid value. Please provide a value between
                                100 - 50000.
                              </FormHelperText>
                            )}
                          </FormControl>
                        </Grid>
                        <Grid
                          item
                          xs={6}
                          sm={3}
                          md={3}
                          style={
                            {
                              // display: 'flex',
                              // flexDirection: 'row',
                            }
                          }
                        >
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={state.useHighPayingKeywords}
                                onChange={handleChange}
                                icon={
                                  <CheckBoxOutlineBlankIcon fontSize="small" />
                                }
                                checkedIcon={<CheckBoxIcon fontSize="small" />}
                                name="useHighPayingKeywords"
                              />
                            }
                            label="High Paying KW(%)"
                          />
                          <FormControl style={{ marginLeft: "2rem" }}>
                            <TextField
                              type="number"
                              name="highPayingKeywords"
                              value={state.highPayingKeywords}
                              onChange={handleChange}
                              error={formOneErrors.highPayingKeywords}
                              autoFocus={formOneErrors.highPayingKeywords}
                              disabled={!state.useHighPayingKeywords}
                              style={{
                                maxWidth: "8ch"
                              }}
                            />
                            {formOneErrors.highPayingKeywords && (
                              <FormHelperText className="error-text">
                                Invalid value. Please provide a value between 0
                                - 100.
                              </FormHelperText>
                            )}
                          </FormControl>
                        </Grid>
                      </Grid>
                    </SimpleAccordion>

                    <Grid container spacing={3} style={{ marginTop: "10px" }}>
                      <Grid item xs={12} sm={12} md={12}>
                        <div className="targeting-form__notes">
                          <TextField
                            label="Description"
                            multiline
                            minRows={2}
                            variant="outlined"
                            name="tagDescription"
                            value={state.tagDescription}
                            onChange={handleChange}
                            fullWidth
                          />
                        </div>
                      </Grid>
                    </Grid>
                  </div>

                  <div>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={state.useClickId}
                          onChange={handleChange}
                          icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                          checkedIcon={<CheckBoxIcon fontSize="small" />}
                          name="useClickId"
                        />
                      }
                      label="Use Click ID"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={state.passReferrer}
                          onChange={handleChange}
                          icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                          checkedIcon={<CheckBoxIcon fontSize="small" />}
                          name="passReferrer"
                        />
                      }
                      label="Pass Referrer"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={state.warmupMode}
                          onChange={handleChange}
                          icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                          checkedIcon={<CheckBoxIcon fontSize="small" />}
                          name="warmupMode"
                        />
                      }
                      label="WarmUp Mode"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={state.temporarilyDisableFilters}
                          onChange={handleChange}
                          icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                          checkedIcon={<CheckBoxIcon fontSize="small" />}
                          name="temporarilyDisableFilters"
                        />
                      }
                      label="Disable filters for first 30 searches"
                    />
                  </div>

                  <div className="targeting-form__publisher-section__bottom flow-content--small">
                    <div className="targeting-form__tag">
                      <div className="targeting-form__tag__link-row">
                        <FormControl fullWidth>
                          <TextField
                            required
                            disabled
                            variant="outlined"
                            label="Link"
                            value={targetLink}
                            error={formOneErrors.targetLink}
                            autoFocus={formOneErrors.targetLink}
                          />
                          {formOneErrors.targetLink && (
                            <FormHelperText className="error-text">
                              Link not generated
                            </FormHelperText>
                          )}
                        </FormControl>
                        {editing && (
                          <div className="button-group">
                            <Tooltip
                              title="Copy Link"
                              onClick={() => handleCopyToClipboard(targetLink)}
                            >
                              <IconButton
                                aria-label="history"
                                className="green-icon"
                              >
                                <AssignmentTurnedInIcon />
                              </IconButton>
                            </Tooltip>
                            <Tooltip
                              title="Check Link"
                              onClick={() => handleCheckTag(targetLink)}
                            >
                              <IconButton
                                aria-label="history"
                                className="copy-icon"
                              >
                                <OpenInBrowserIcon color="primary" />
                              </IconButton>
                            </Tooltip>
                          </div>
                        )}
                      </div>
                      <FormControlLabel
                        control={
                          <InfoSharp
                            className="info-icon"
                            style={{ color: "blue" }}
                            fontSize="large"
                          />
                        }
                        label="Link is auto generated based on input and is read-only."
                      />
                    </div>
                  </div>
                </div>

                {!(platform && searchEngine && tagType && qualityScore) ? (
                  <div className="spacing__top">
                    <Alert
                      type="warning"
                      message="Please select all the Publisher Link Properties before assigning the rules."
                    />
                  </div>
                ) : (
                  false
                )}

                <div>
                  <Divider />
                  {duplicatedProviderLinks.length > 0 && (
                    <DuplicateLinksMsg
                      duplicatedIds={duplicatedProviderLinks || []}
                    />
                  )}
                  <div className="target__section">
                    <FormLabel component="legend">Targeting</FormLabel>
                    <RadioGroup
                      aria-label="targeting"
                      name="targeting"
                      value={state.targeting}
                      onChange={handleChange}
                      className="radio-section"
                    >
                      <div className="targeting-option">
                        <FormControlLabel
                          value={TARGETING_TYPES.ROUND_ROBIN}
                          control={<Radio />}
                          label="Round Robin"
                        />
                        <InfoToolTip
                          title={ROUND_ROBIN_HELP_TEXT}
                          className="targeting-option__info"
                        >
                          <div className="gray-icon ">
                            <HelpIcon fontSize="small" />
                          </div>
                        </InfoToolTip>
                      </div>
                      <div className="targeting-option">
                        <FormControlLabel
                          value={TARGETING_TYPES.WATERFALL}
                          control={<Radio />}
                          label="Waterfall"
                        />
                        <InfoToolTip
                          title={WATERFALL_HELP_TEXT}
                          className="targeting-option__info"
                        >
                          <div className="gray-icon ">
                            <HelpIcon fontSize="small" />
                          </div>
                        </InfoToolTip>
                      </div>
                    </RadioGroup>
                  </div>
                  {targetingRules && (
                    <TargetRule
                      editing={editing}
                      targeting={initialTargeting}
                      targeting_type={state.targeting}
                      addMoreRulesBlock={addMoreRulesBlock}
                      rulesBlockNumber={rulesBlockNumber}
                      logicError={logicError}
                      oldRules={oldRules}
                      targetingID={targetingID}
                      publisher={publisher}
                      targetingRules={targetingRules}
                      targetingRulesError={targetingRulesError}
                      handleFormTouched={handleFormTouched}
                      linkStatus={activate}
                      targetingLinkStatus={state.status}
                      platform={platform}
                      searchEngine={searchEngine}
                      tagType={tagType}
                      qualityScore={qualityScore}
                      dailyCap={dailyCap}
                      publisherChannelStats={publisherChannelStats}
                      setDailyCap={setDailyCap}
                      isWeightFieldTouched={isWeightFieldTouched}
                      setIsWeightFieldTouched={setIsWeightFieldTouched}
                      defaultRevenueShare={defaultRevenueShare}
                    />
                  )}
                  <div className="default-url-block gray-text">
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={state.cusFallback}
                            onChange={handleChange}
                            icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                            checkedIcon={<CheckBoxIcon fontSize="small" />}
                            name="cusFallback"
                          />
                        }
                        label="Use Custom Fallback URL"
                      />
                      <InfoToolTip
                        title={CUSTOM_FALLBACK_HELP_TEXT}
                        className="custom-fallback__info"
                      >
                        <div className="gray-icon ">
                          <HelpIcon fontSize="small" />
                        </div>
                      </InfoToolTip>
                    </div>

                    <div className="default-url-block__fallback">
                      <FormControl>
                        <TextField
                          name="fallback"
                          variant="outlined"
                          style={{ width: "40vw" }}
                          value={
                            !state.cusFallback
                              ? DEFAULT_FALLBACK
                              : state.fallback
                          }
                          onChange={handleChange}
                          error={formOneErrors.fallback}
                          autoFocus={formOneErrors.fallback}
                          disabled={!state.cusFallback}
                        />
                        {formOneErrors.fallback && (
                          <FormHelperText className="error-text">
                            Valid link required
                          </FormHelperText>
                        )}
                      </FormControl>
                    </div>
                  </div>

                  <div className="default-url-block gray-text">
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={state.cusOvercapUrl}
                            onChange={handleChange}
                            icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                            checkedIcon={<CheckBoxIcon fontSize="small" />}
                            name="cusOvercapUrl"
                          />
                        }
                        label="Use Custom OverCap URL"
                      />
                    </div>
                    <div className="default-url-block__overcap">
                      <FormControl>
                        <TextField
                          name="overcapUrl"
                          variant="outlined"
                          style={{ width: "40vw" }}
                          value={state.overcapUrl}
                          onChange={handleChange}
                          error={formOneErrors.fallback}
                          autoFocus={formOneErrors.fallback}
                          disabled={!state.cusOvercapUrl}
                        />
                        {formOneErrors.fallback && (
                          <FormHelperText className="error-text">
                            Valid link required
                          </FormHelperText>
                        )}
                      </FormControl>
                    </div>
                  </div>

                  <div className="footer-section">
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => handleFormButtonClick("draft")}
                      startIcon={<DoneIcon />}
                    >
                      Save as draft
                    </Button>
                    <Button
                      variant="contained"
                      className="save-publish"
                      color="primary"
                      onClick={() => handleFormButtonClick("published")}
                      startIcon={<PublishIcon />}
                    >
                      Save & Publish
                    </Button>
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={() => {
                        history.push("/target");
                      }}
                      startIcon={<CloseIcon />}
                    >
                      Cancel
                    </Button>
                  </div>
                </div>
              </>
            ) : (
              <SavingMessage />
            )}

            {openWeightMessage.modal && (
              <ConfirmBox
                message="Please note that Total weight is less than 100%."
                // confirmAction={() => {
                //   handleFormSubmit(openWeightMessage.type);
                //   handleWeightMessageModalClose();
                // }}
                confirmAction={handleWeightMessageModalClose}
                hideCloseButton={true}
                affirmativeText="OK"
              />
            )}

            {openWeightMessage.modal && (
              <ConfirmBox
                message="Please note that Total weight is less than 100%."
                // confirmAction={() => {
                //   handleFormSubmit(openWeightMessage.type);
                //   handleWeightMessageModalClose();
                // }}
                confirmAction={handleWeightMessageModalClose}
                hideCloseButton={true}
                affirmativeText="OK"
              />
            )}

            <Prompt
              when={
                showPrompt && formTouched.current
                // settingsObject?.default_domain !== undefined &&
                // settingsObject?.default_fallback !== undefined
              }
              message="Are you sure you want to leave?"
            />
            {linkPropertiesModal && (
              <ConfirmBox
                message="All rulesets related to this link property will be disabled. Are you sure to remove the link property?"
                confirmAction={confirmLinkPropertyChange}
                denyAction={resetLinkPropertyChange}
                affirmativeText="Proceed"
                negativeText="Cancel"
              />
            )}
            {saveDraftStatus && (
              <ConfirmBox
                message={DRAFT_INFO_MESSAGE}
                confirmAction={() => handleSaveUpdate("draft")}
                denyAction={() => setSaveDraftStatus(false)}
                affirmativeText="Ok"
                negativeText="Cancel"
              />
            )}
          </div>
        </SearchEngineContext.Provider>
      </PlatformContext.Provider>
    </TagTypeContext.Provider>
  );
}
